<template>
 <div class="navigation-sidebar">
    <ul class="nav flex-column" >
      <img class="img-fluid" src="../../assets/images/SH-Verde-Horiz@2x.7ce60d99.png" style="width: 153px;">
      <div class="row user-info" style="display: flex;">
        <template v-if="!affiliate">
          <div class="icon-loading">
              <i class="fas fa-circle-notch fa-spin"></i>
          </div>
          <div>
            <div class="name-loading"></div>
            <div class="emissions-loading"></div>
          </div>
        </template>
        <template v-else>
          <div class="icon-user">
            <img v-if="affiliate.imageUrl" class="affiliate-photo" :src="affiliate.logoUrl" alt="">
            <img v-else-if="affiliate.logoUrl" class="affiliate-photo" :src="affiliate.logoUrl" alt="">
            <i v-else class="fas fa-user"></i>
          </div>
          <div class="user-container">
            <template v-if="affiliate && affiliate.name">
              <div class="affiliate-info">
                <p class="name-user">{{ this.capitalize(affiliate.name) }}</p>
                <p class="emissions-user">{{ affiliate.position }}</p>
              </div>
              <button type="button" class="btn btn-sm btn-notify">
                <div v-if="notifications.length > 0" class="notifications-counter">
                  {{ this.notifications.length }}
                </div>
                <div v-else class="notifications-counter" style="background-color: #5b5d6b;">
                  {{ this.notifications.length }}
                </div>
                <svg width="12" height="16" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.99999 18C9.24171 18 10.2489 16.9928 10.2489 15.75H5.75105C5.75105 16.9928 6.75827 18 7.99999 18ZM15.5723 12.7368C14.8931 12.0069 13.6222 10.909 13.6222 7.3125C13.6222 4.58086 11.7069 2.39414 9.12429 1.85766V1.125C9.12429 0.503789 8.62085 0 7.99999 0C7.37913 0 6.8757 0.503789 6.8757 1.125V1.85766C4.29312 2.39414 2.37781 4.58086 2.37781 7.3125C2.37781 10.909 1.10691 12.0069 0.427689 12.7368C0.216752 12.9635 0.123236 13.2346 0.124994 13.5C0.128861 14.0766 0.581322 14.625 1.25351 14.625H14.7465C15.4187 14.625 15.8715 14.0766 15.875 13.5C15.8768 13.2346 15.7832 12.9632 15.5723 12.7368Z" fill="#2FB490"/>
                </svg>
              </button>
            </template>
          </div>
        </template>
      </div>
      <li v-if="managementOn" class="nav-item">
        <router-link
          exact
          class="nav-link"
          exact-active-class="active"
          :to="{ name: 'management' }"
        >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="16.0017" cy="5.9975" r="4.00167" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M21.0037 10.9996L18.8308 8.82668" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M16.0017 21.0038H18.0025C19.66 21.0038 21.0037 19.66 21.0037 18.0025V15.0013" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M2.99625 10.9996V6.99792C2.99625 5.34037 4.33995 3.99667 5.9975 3.99667H7.99833" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <rect x="2.99625" y="17.2522" width="3.00125" height="3.75156" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <rect x="5.9975" y="13.0004" width="3.00125" height="8.00333" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <rect x="8.99875" y="15.2514" width="3.00125" height="5.7524" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <!-- <i class="fas fa-chart-pie"></i> -->
        Gestão
        </router-link>
      </li>
      <li v-if="affiliate.position !== 'SDR'" class="nav-item">
        <router-link
          exact
          class="nav-link"
          exact-active-class="active"
          :to="{ name: 'home' }"
        >
        <svg class="home-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3 9V6C3 4.895 3.895 4 5 4H19C20.105 4 21 4.895 21 6V18C21 19.105 20.105 20 19 20H13" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M9 20L8.749 19.37C8.622 19.051 8.425 18.764 8.173 18.531V18.531C7.746 18.135 7.185 17.916 6.604 17.916H4.396C3.814 17.916 3.254 18.136 2.827 18.531V18.531C2.575 18.764 2.378 19.051 2.251 19.37L2 20" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M6.737 12.513C7.42 13.196 7.42 14.304 6.737 14.988C6.054 15.671 4.946 15.671 4.262 14.988C3.579 14.305 3.579 13.197 4.262 12.513C4.946 11.829 6.054 11.829 6.737 12.513" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M11 15V9" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14 15V11.68" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M17 15V9.55" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <!-- <i class="fas fa-chart-pie"></i> -->
        Resumo
        </router-link>
      </li>
      <li v-if="affiliate.position !== 'SDR'" class="nav-item">
        <router-link
          exact
          class="nav-link"
          exact-active-class="active"
          :to="{ name: 'users' }"
        >
          <svg class="users-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.091 8.15901C10.9697 9.03769 10.9697 10.4623 10.091 11.341C9.21233 12.2197 7.78771 12.2197 6.90903 11.341C6.03035 10.4623 6.03035 9.03769 6.90903 8.15901C7.78771 7.28033 9.21233 7.28033 10.091 8.15901" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 18.5V5.541C2 4.414 2.914 3.5 4.041 3.5H20C21.105 3.5 22 4.395 22 5.5V18.5C22 19.605 21.105 20.5 20 20.5H4C2.895 20.5 2 19.605 2 18.5Z" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M15 9.5H19" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.4 13.5H15" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.095 16.5C11.92 16.061 11.65 15.667 11.303 15.346V15.346C10.716 14.802 9.946 14.5 9.146 14.5H7.854C7.054 14.5 6.284 14.802 5.697 15.346V15.346C5.35 15.667 5.08 16.061 4.905 16.5" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <!-- <i class="fas fa-building"></i> -->
          Clientes
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          exact
          class="nav-link"
          exact-active-class="active"
          :to="{ name: 'leads' }"
        >
          <svg class="hotleads-svg" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.19804 7.55934L9.68083 3.49609L13.4357 7.87676L15.8652 5.04219L17.802 7.30168C19.0464 8.75357 19.7305 10.6027 19.7305 12.515V12.7726C19.7305 14.8229 18.916 16.7892 17.4663 18.239C16.0165 19.6887 14.0503 20.5032 12 20.5032V20.5032C7.73056 20.5032 4.2695 17.0421 4.2695 12.7727V12.7727C4.2695 10.8604 4.95356 9.01124 6.19804 7.55934Z" stroke="#323232" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <!-- <i class="fas fa-fire-alt"></i> -->
          Leads
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          exact
          class="nav-link"
          exact-active-class="active"
          :to="{ name: 'simulador' }"
        >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M18 3H6C4.895 3 4 3.895 4 5V19C4 20.105 4.895 21 6 21H18C19.105 21 20 20.105 20 19V5C20 3.895 19.105 3 18 3Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14 7.5L11.5 10L10 8.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 14H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 17H16" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
          Simulador
        </router-link>
      </li>
      <li class="nav-item">
        <a class="nav-item nav-link last" @click="doLogout()" href="#" style="color: var(--red-light); margin-left: 2px;">
          <svg  width="24" height="24" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5 29.088V11.2545C16.5 10.2195 15.9675 9.25797 15.09 8.71047L9.09 4.96047C7.092 3.71247 4.5 5.14797 4.5 7.50447V25.3365C4.5 26.3715 5.0325 27.333 5.91 27.8805L11.91 31.6305C13.908 32.88 16.5 31.443 16.5 29.088Z" stroke="#DE6767" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.5 16.5H31.5" stroke="#DE6767" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M28.5 19.5L31.5 16.5L28.5 13.5" stroke="#DE6767" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M16.5 28.5H22.5C24.1575 28.5 25.5 27.1575 25.5 25.5V24" stroke="#DE6767" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25.5 9V7.5C25.5 5.8425 24.1575 4.5 22.5 4.5H7.5" stroke="#DE6767" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          Sair
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
// import moment from 'moment'
import Vue from 'vue'

export default {
  data: () => ({
    visible: true,
    affiliate: {},
    users: [],
    oldPlansUsers: [],
    plans: [],
    newSubscriptions: [],
    transations: [],
    notifications: [],
    changeColor: false,
    managementOn: false
  }),
  mounted () {
    this.getAffiliate()
    this.$root.$on('verifyActived', this.verifyActived)
  },
  methods: {
    capitalize (data) {
      const nameSplit = data.split(' ')
      if (nameSplit.length >= 2 ) {
        var capitalized = []
        var dataTemp = data + ''
        dataTemp.split(' ').forEach(word => {
          capitalized.push(
            word.charAt(0).toUpperCase() +
            word.slice(1).toLowerCase()
          )
        })
        return capitalized[0] + ' ' + capitalized[1]
      }

      return data
    },
    async doLogout () {
      this.$root.$emit('showLayout::hide')
      this.$root.$emit('Spinner::show')
      await this.$firebase.auth().signOut()
      this.$router.push({ name: 'login' })
      this.$root.$emit('Spinner::hide')
    },
    async getPlans () {
      await this.$firebase.database().ref('plans').on('value', snapshot => {
        this.plans = snapshot.val()
      })
      await this.$firebase.database().ref('subscriptions').on('value', snapshot => {
        const values = snapshot.val()
        this.newSubscriptions = values.data
        this.transations = values.transations
        let listTransitions = []
        const tempObject = {}
        for (const i in this.newSubscriptions) {
          if (this.transations[i]) {
            listTransitions = Object.keys(this.transations[i]).map(j => this.transations[i][j])
            for (let i = 0; i < listTransitions.length; i++) {
              tempObject[listTransitions[i].expirationDate] = listTransitions[i]
            }
            this.newSubscriptions[i].lastTransations = tempObject
          }
        }
      })
    },
    getNewPlan (user) {
      const emptyPlan = { name: 'Grátis' }
      if (this.newSubscriptions[user.uId]) {
        return { name: this.newSubscriptions[user.uId].name }
      } else {
        return emptyPlan
      }
    },
    getNewSubscription (user) {
      if (this.newSubscriptions[user.uId]) {
        if (this.newSubscriptions[user.uId].status === 'paid') {
          user.pagarmeSubscription = this.newSubscriptions[user.uId]
          return 'Pago'
        } else if (this.newSubscriptions[user.uId].status === 'canceled') {
          user.pagarmeSubscription = this.newSubscriptions[user.uId]
          return 'Cancelado'
        } else {
          user.pagarmeSubscription = this.newSubscriptions[user.uId]
          return 'Inadimplente'
        }
      } else {
        return 'Não Assinante'
      }
    },
    async getAffiliate () {
      await this.$firebase.database().ref(`affiliates/${window.uid}`).on('value', snapshot => {
        const affiliate = snapshot.val()
        if (affiliate) {
          console.log(affiliate.position)
          affiliate.position = affiliate.position ? affiliate.position : 'SDR'
          if (affiliate.position.toLowerCase() === 'adm' || affiliate.position.toLowerCase() === 'master') {
            this.managementOn = true
          }
          this.affiliate = affiliate
        }
      })
      this.$root.$emit('verifyActived')
    },
    async getUsers () {
      // this.searchValue = this.$router.history.current.params.searchValue ? this.$router.history.current.params.searchValue : ''
      await this.$firebase.database().ref('users').on('value', snapshot => {
        let values = snapshot.val()
        values = Object.keys(values).map(i => values[i])
        this.users = []
        this.oldPlansUsers = []
        if (values.length) {
          for (let i = 0; i < values.length; i++) {
            if (values[i].affiliateId !== window.uid) {
              if (values[i].currentPlanId === '') {
                values[i].pagarmePlan = this.getNewPlan(values[i], values[i].currentPlanId)
                values[i].pagarmePaymentStatus = this.getNewSubscription(values[i])
                if (this.usersStatus[values[i].uId]) {
                  values[i].status = this.usersStatus[values[i].uId]
                }
                this.users.push(values[i])
              } else {
                this.oldPlansUsers.push(values[i])
              }
            }
          }
        }
      })
      // Usar caso a tabela estiver com o bug no carregamento
      // this.users = this.users.sort((a, b) => Number(a.status.dtCallback) - Number(b.status.dtCallback))
    },
    async getInfo () {
      await this.$firebase.database().ref(`support/notifications/${window.uid}`).on('value', snapshot => {
        const values = snapshot.val()
        if (values) {
          this.notifications = Object.keys(values).map(j => values[j])
          this.notifications.pop()
          // this.notifications = this.notifications.filter(n => new Date(Date.now()).getDate() === new Date(n.date).getDate())
        }
      })
    },
    verifyActived () {
      if (this.affiliate && this.affiliate.actived === false) {
        this.doLogout()
        Vue.prototype.$actived = false
      }
      Vue.prototype.$actived = true
    },
    checkRoute () {
      console.log(this.$router.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.col-lg-2{
  padding: 0;
  margin: 0;
}
.left-icon{
  position: absolute;
  transform: rotateX(180deg) rotateY(180deg) rotate(270deg) translate(3px, 27px);
}
.dropdown-box{
  margin: 0 2px 0px 28px;
  padding-bottom: 15px;
  background-color: #fafafa;
  border-radius: 5px;
  font-size: 13.7px !important;
  font-weight: 400 !important;
  // &:not(.actived) li a{
  //   transform: translate(-200px, 0) !important;
  //   transition: all .3s !important;
  // }
  &.actived li a{
    transform: translate(21px, 0) !important;
    border-left: 1px solid #e1e1e1;
    border-radius: 0px !important;
    padding-left: 32px !important;
  }
}
.navigation-sidebar{
  position: fixed;
  top: 0;
  float: right;
  height: calc(100% - 0px) !important;
  // height: 100vh;
  overflow: hidden auto;
  width: 245px;
  // border-radius: 9px;
  padding: 10px 5px;
  box-shadow: 0px 0px 6px 4px rgba(0,0,0,0.1);
  background-color: white;
  z-index: 100;
  &::-webkit-scrollbar {
    display: none !important;
  }
  .nav-link{
    display: flex;
    cursor: pointer;
    color: #505050;
    //color: var(--featured) !important;
    padding: .70rem 0 .70rem 1rem !important;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 400;
    &.active{
      //background-color: var(--featured) !important;
      //color: white;
      background-color: #e9f4f2;
      color: #046e60 !important;
      margin-right: -25px !important;
      z-index: 999;
      .new-box{
        font-size: 9px;
        font-weight: 500;
        //color: var(--featured);
        //background-color: white;
        background-color: #046e60;
        color: white;
        border-radius: 2px;
        padding: 1.9px 5px 1px 5px;
        transform: translate(7px, -1px);
        position: absolute
      }
      svg{
        path {
          stroke: #046e60;
          // fill: #e9f4f2;
        }
        circle {
          stroke: #046e60;
        }
        rect {
          stroke: #046e60;
        }
      }
    }
    &:hover{
      background-color: #e9f4f2;
      color: #046e60 !important;
      //box-shadow: 0px 0px 4px 3px rgba(0,0,0,0.05) !important;
      .new-box{
        background-color: #046e60 !important;
      }
      svg{
        path {
          stroke: #046e60;
          // fill: #e9f4f2;
        }
        circle {
          stroke: #046e60;
        }
        rect {
          stroke: #046e60;
        }
      }

      &.last {
        background-color: #fbe6e6 !important;
        color: var(--red-light) !important;

        svg{
          path {
            stroke: #DE6767;
            // fill: #e9f4f2;
          }
        }
      }
    }
    &.logout{
      &:hover{
        background-color: rgba(232, 121, 121, .09) !important;
        color: var(--red-light) !important;
      }
    }
    &.disabled{
      color: #c1c1c1 !important;
      svg{
        fill:#c1c1c1 !important;
      }
      // color: white;
      .new-box{
        font-size: 9px;
        font-weight: 500;
        //color: var(--featured);
        //background-color: white;
        background-color: #c1c1c1 !important;
        color: white;
        border-radius: 2px;
        padding: 2px 5px 1px 5px;
        transform: translate(7px, -1px);
        position: absolute
      }

    }

    .new-box{
      font-size: 9px;
      font-weight: 500;
      color: white;
      background-color: #505050;
      border-radius: 2px;
      padding: 1.9px 5px 1px 5px;
      transform: translate(7px, -1px);
      position: absolute
    }
  }
  i, svg{
    height: 21px !important;
    width: 21px !important;
    font-size: 20px;
    margin-right: 13px;
  }
  .icon-ajust{
    margin-left: 2px;
    margin-right: 10px;
  }
  img{
    display: flex;
    width: 56%;
    justify-content: center;
    margin: 27px auto;
  }
  li{
    margin: 3.5px 5px;
    letter-spacing: .25px;
  }
  .nav-link:focus {
      outline:none !important;
      box-shadow: none !important;
  }
  .nav-link::selection {
      color: none;
      background: none;
  }
  .nav-link::-moz-selection {
      color: none;
      background: none;
  }
}
.user-info{
  background: var(--green-light);
  padding: 10px 10px;
  border-radius: 9px;
  margin: 0 -15px 5px 5px !important;
  .btn-logout-profile{
    background: #fff;
    border: none;
    padding: 1px 4px !important;
    border-radius: 50% !important;
    position:absolute;
    height: 28px;
    width: 28px;
    transform: translate(25px, 24px);
    i{
      font-size: 13px !important;
      margin: 0;
      transform: translate(0, 1px);
      color: var(--red-light)
    }
  }
  .photo{
    background-size: cover;
    background-position: center center;
    height: 45px;
    width: 45px;
    text-align: center;
    border-radius: 7px;
    margin-right: 10px;
    border: solid 1.5px white;
  }
  .name-user{
    font-size: 11px;
    font-weight: 700;
    margin: 4px 0;
  }
  .emissions-user{
    font-size: 11px;
    font-weight: 600;
    margin: 3px 0;
    color: var(--dark)
  }
  .name-loading{
    background-color: #dbdbdb !important;
    height: 19px;
    width: 105px;
    border-radius: 3px;
    margin: 3px 0;
  }
  .emissions-loading{
    background-color: #dbdbdb !important;
    height: 18px;
    width: 100px;
    border-radius: 3px;
    margin: 3px 0;
  }
  .icon-user{
    background-color: transparent !important;
    background: #ffc0c0;
    height: 45px;
    width: 45px;
    border-radius: 7px;
    text-align: center;
    margin-right: 10px;
    i{
      color: white;
      height: 45px;
      width: 45px !important;
      vertical-align: middle;
      item-align: center;
      line-height: 40px;
    }

    .affiliate-photo {
      height: 45px;
      width: 45px;
      border-radius: 7px;
      margin-top: 0px;
    }
  }
  .icon-loading{
    background-color: var(--featured) !important;
    background: #ffc0c0;
    height: 45px;
    width: 45px;
    border-radius: 7px;
    text-align: center;
    margin-right: 10px;
    i{
      margin: 0 !important;
      color: white;
      height: 45px !important;
      width: 45px !important;
      vertical-align: middle;
      item-align: center;
      line-height: 45px;
      font-size: 15px
    }
  }
  .user-container {
    display: flex;
    gap: 25px;
    align-items: center;
    .btn-notify {
      height: 35px !important;
      width: 40px !important;
      padding: 10px 10px !important;
      background-color: #fff;
      box-shadow: 0px 0px 3px 2px rgba(0,0,0,0.10);
      .notifications-counter {
        background-color: #de6767;
        padding-top: 2px;
        width: 20px;
        height: 19px;
        border-radius: 5px;
        margin-top: -20px;
        margin-left: 15px;
        color: #FFFFFF;
        font-size: 10px;
      }
      svg {

      }
    }
  }
}
.questionsIcon {
  width: 50px;
  height: 50px;
}
</style>
